<template>
    <div v-if="loaded" class="fill-height" :key="componentKey">
        <login-top-menu :mini="mini" :drawer="drawer" @nav-mini-toggle="navMiniToggle"
                        @nav-toggle="navToggle">
        </login-top-menu>
        <v-main>
            <transition name="fade" mode="out-in">
                <router-view ></router-view>
            </transition>
        </v-main>
        <v-footer bottom fixed padless>
        <app-footer></app-footer>
        </v-footer>
    </div>

</template>

<script>

    import Vue from 'vue'
    import mixin from '../../plugins/mixin'

    Vue.mixin(mixin)

    import Echo from "laravel-echo";
    import {mapGetters, mapActions} from "vuex"
    import AppFooter from "../../components/AppFooter"
    import LoginTopMenu from "../../components/LoginTopMenu";

    export default {
        name: "MainLayout",
        components: {
            LoginTopMenu,
            AppFooter
        },
        props: {
            source: String,
            push: Object
        },
        provide: function () {
            return {
                forceRerender: this.forceRerender,
            }
        },
        data() {
            return {
                loaded: false,
                mini: false,
                drawer: false,
                url_pdf_file: null,
                order_number_pdf_file: null,
                dialog_download_update: false,
                componentKey: 0,
            }
        },
        computed: {
            ...mapGetters(['lang', 'defaultAvatar']),
        },
        watch: {
            mini(val) {
                if (this.$vuetify.breakpoint.smAndDown || !this.drawer) {
                    this.mini = false
                } else {
                    this.mini = (this.$vuetify.breakpoint.smAndUp && val)
                }
            },
        },
        async mounted() {
            await this.$auth
                .load()
                .then(() => {
                    if (this.$auth.check() && this.$auth.user() && this.$auth.user().settings) {
                        this.setSettings(this.$auth.user().settings)
                        this.setUserName(this.$auth.user().name)
                        this.setUserPosition(this.$auth.user().position)
                        this.setUserAvatar((this.$auth.user().photo ? this.$auth.user().photo : this.defaultAvatar))
                        //this.listenChannelWebsocket()
                        this.listenChannelFCM()
                        //this.listenChannelAPN()
                    }
                    this.loaded = true
                })

            this.setLang()

            this.drawer = this.$vuetify.breakpoint.mdAndUp
            this.mini = this.$vuetify.breakpoint.mdOnly
        },
        methods: {
            ...mapActions(['setSettings', 'setLanguage', 'setUserName', 'setUserPosition', 'setUserAvatar']),
            getCookie(name) {
                var nameEQ = name + "=";
                var ca = document.cookie.split(';');
                for(var i=0;i < ca.length;i++) {
                    var c = ca[i];
                    while (c.charAt(0)===' ') c = c.substring(1,c.length);
                    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
                }
                return null;
            },
            setLang() {
                let language = this.getCookie('language')
                if (language == null && this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                    document.documentElement.lang = this.$auth.user().language

                }
                else {
                    document.documentElement.lang = language
                }
                this.$vuetify.lang.current = document.documentElement.lang
                this.$i18n.locale = document.documentElement.lang
                this.setLanguage(document.documentElement.lang)
                if (typeof this.$i18n.locale !== 'undefined') {
                    this.$i18n.locale = document.documentElement.lang
                }
                this.$moment.locale(this.$i18n.locale)
                if (typeof this.$vuetify.lang.current !== 'undefined') {
                    this.$vuetify.lang.current = document.documentElement.lang
                }
                this.forceRerender()
            },
            forceRerender: function () {
                this.componentKey += 1
            },
            navToggle() {
                this.drawer = !this.drawer
                if (!this.drawer) {
                    this.navMiniToggle()
                }
            },
            navMiniToggle() {
                this.mini = !this.mini
            },
            listenChannelWebsocket() {
                window.Echo = new Echo({
                    broadcaster: "pusher",
                    key: process.env.VUE_APP_PUSHER_APP_KEY,
                    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
                    disableStats: true,
                    encrypted: true,
                    enabledTransports: ["wss", "ws"],
                    //wsHost: window.location.hostname,
                    wsHost: this.$pusherServer,
                    authorizer: (channel, options) => {
                        return {
                            authorize: (socketId, callback) => {
                                this.$http.post(`https://${this.$pusherServer}/api/broadcasting/auth`, {
                                    socket_id: socketId,
                                    channel_name: channel.name
                                })
                                    .then(response => {
                                        callback(false, response.data)
                                    })
                                    .catch(error => {
                                        callback(true, error)
                                    })
                            }
                        }
                    }
                })
                window.Echo.private(`App.Models.Client.${this.$auth.user().id}`)
                    .listen('.Event.Client', (event) => {
                        console.log(event.data)


                        if (event.data.type.indexOf('file_pdf') !== -1) {
                            this.dialog_download_update = true;
                            this.url_pdf_file = event.data.url;
                            this.order_number_pdf_file = event.data.message_param.number;
                        }

                        if (event.data.type.indexOf('notify') !== -1) {
                            this.$toastr.success(`${event.title} ${event.message}`)
                        }
                    })
                /*
                .notification((notify) => {
                    //console.log('MainLayout notify')
                    console.log(notify)
                    if (notify.type.indexOf('broadcast.message') !== -1) {
                        this.$toastr.success(`${notify.title} ${notify.message}`)
                    }
                })
               */
            },
            open_print_file(){
                window.open(this.url_pdf_file);
                this.dialog_download_update = false;
            },
            listenChannelFCM() {
                if (window.messaging) {
                    /*
                    window.messaging.requestPermission()
                        .then(() => {
                            console.log('Notification permission granted.')
                            window.messaging.getToken().then((token) => {
                                //console.log('New token created: ', token)
                                this.saveNotificationToken(token)
                                this.setTokenToLocalStorage(false);
                            })
                        })
                        .catch((err) => {
                            console.log('Unable to get permission to notify.', err)
                            this.setTokenToLocalStorage(false);
                        })


                    window.messaging.onTokenRefresh(function () {
                        window.messaging.getToken()
                            .then(function (newToken) {
                                //console.log('Token refreshed: ', newToken)
                                this.saveNotificationToken(newToken)
                                this.setTokenToLocalStorage(newToken);
                            })
                            .catch(function (err) {
                                console.log('Unable to retrieve refreshed token ', err)
                                this.setTokenToLocalStorage(false);
                            })
                    })
                     */
                    window.messaging.onMessage(function (payload) {
                        Notification.requestPermission(function (result) {
                            if (result === "granted") {
                                navigator.serviceWorker.ready
                                    .then(function (registration) {
                                        const data = {...payload.notification, ...payload.data};
                                        const notificationTitle = data.title;
                                        let notificationOptions = {
                                            body: data.body,
                                            requireInteraction: true,
                                            data
                                        }
                                        if (data.icon) {
                                            notificationOptions.icon = data.icon
                                        }
                                        if (data.image) {
                                            notificationOptions.image = data.image
                                        }
                                        if (data.click_action) {
                                            notificationOptions.click_action = data.click_action
                                        }
                                        if(data.type){
                                            switch (data.type){
                                                case 'orders':
                                                    if(data.uuid){
                                                        notificationOptions.data.click_action = data.type+'/'+data.uuid;
                                                    }
                                                break;
                                            }
                                        }
                                        return registration.showNotification(
                                            notificationTitle,
                                            notificationOptions
                                        )
                                    })
                                    .catch(function (error) {
                                        console.log("ServiceWorker registration failed", error);
                                    })
                            }
                        })
                    })
                }
            },
            saveNotificationToken(token) {
                const params = {
                    token: token,
                    type: 'browser'
                }
                this.$http
                    .post("courier/device", params)
                    .then((res) => {
                        //console.log('Successfully saved notification token!')
                    })
                    .catch((err) => {
                        console.log('Error: could not save notification token')
                    })
            },
            isTokenInLocalStorage(currentToken) {
                return window.localStorage.getItem('sentFirebaseMessagingToken') === currentToken
            },
            setTokenToLocalStorage(currentToken) {
                window.localStorage.setItem(
                    'sentFirebaseMessagingToken',
                    currentToken ? currentToken : ''
                )
            },
            listenChannelAPN() {
                var checkRemotePermission = function (permissionData) {
                    if (permissionData.permission === 'default') {
                        // This is a new web service URL and its validity is unknown.
                        console.log("default")
                        window.safari.pushNotification.requestPermission(
                            'https://localhost:8080//',
                            'web.com.website.notify',
                            {uid: "TEST"},
                            checkRemotePermission
                        )
                    }
                    else if (permissionData.permission === 'denied') {
                        // The user said no.
                        console.log("no")
                    }
                    else if (permissionData.permission === 'granted') {
                        // The web service URL is a valid push provider, and the user said yes.
                        // permissionData.deviceToken is now available to use.
                        console.log("yes")
                    }
                }
                document.body.onload = function () {
                    if ('safari' in window && 'pushNotification' in window.safari) {
                        var permissionData = window.safari.pushNotification.permission('web.com.website.notify');
                        this.checkRemotePermission(permissionData);
                    } else {
                        console.log("This feature is only available on Mac OS X safari")
                    }
                }
            },
            checkRemotePermission: function (permissionData) {
                if (permissionData.permission === 'default') {
                    // This is a new web service URL and its validity is unknown.
                    console.log("default");
                    window.safari.pushNotification.requestPermission(
                        'https://localhost:8080//',
                        'web.com.website.notify',
                        {uid: "TEST"},
                        this.checkRemotePermission
                    );
                } else if (permissionData.permission === 'denied') {
                    // The user said no.
                    console.log("no");
                } else if (permissionData.permission === 'granted') {
                    // The web service URL is a valid push provider, and the user said yes.
                    // permissionData.deviceToken is now available to use.
                    console.log("yes");
                }
            },
        }
    }
</script>
