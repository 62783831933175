<template>
    <div v-if="loaded" class="fill-height" :key="componentKey">
        <login-top-menu :mini="mini" :drawer="drawer" @nav-mini-toggle="navMiniToggle"
                      @nav-toggle="navToggle">
        </login-top-menu>

        <v-main style="height: 100%">

            <transition name="fade" mode="out-in">
                <router-view ></router-view>
            </transition>
        </v-main>
    </div>
</template>
<script>
import LoginTopMenu from "../../components/LoginTopMenu"
import {mapActions} from "vuex";

    export default {
        name: "login-page-layout",
        components: {
            LoginTopMenu,
        },
        provide: function () {
            return {
                forceRerender: this.forceRerender,
            }
        },
        data() {
            return {
                loaded: false,
                mini: false,
                drawer: false,
                componentKey: 0,
            }
        },
        watch: {
            mini(val) {
                if (this.$vuetify.breakpoint.smAndDown || !this.drawer) {
                    this.mini = false
                } else {
                    this.mini = (this.$vuetify.breakpoint.smAndUp && val)
                }
            },
        },
        async mounted() {
            this.setLang()
            this.drawer = this.$vuetify.breakpoint.mdAndUp
            this.mini = this.$vuetify.breakpoint.mdOnly
            this.loaded = true
        },
        methods: {
            ...mapActions(['setLanguage']),
            setLang() {
                let language = this.getCookie('language')

                if (language) {
                    document.documentElement.lang = language
                    this.$vuetify.lang.current = document.documentElement.lang
                    this.$i18n.locale = document.documentElement.lang
                    this.setLanguage(document.documentElement.lang)
                }
                if (typeof this.$i18n.locale !== 'undefined') {
                    this.$i18n.locale = document.documentElement.lang
                }
                this.$moment.locale(this.$i18n.locale)
                if (typeof this.$vuetify.lang.current !== 'undefined') {
                    this.$vuetify.lang.current = document.documentElement.lang
                }
                this.forceRerender()
            },
            getCookie(name) {
                var nameEQ = name + "=";
                var ca = document.cookie.split(';');
                for(var i=0;i < ca.length;i++) {
                    var c = ca[i];
                    while (c.charAt(0)==' ') c = c.substring(1,c.length);
                    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
                }
                return null;
            },
            forceRerender: function () {
                this.componentKey += 1
            },
            navToggle() {
                this.drawer = !this.drawer
                if (!this.drawer) {
                    this.navMiniToggle()
                }
            },
            navMiniToggle() {
                this.mini = !this.mini
            },
        }
    }
</script>

