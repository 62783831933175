<template>
    <v-bottom-navigation v-model="value" inset absolute app
                         class="pt-3 bottomNavigation"
                         :value="value"
                         background-color="primary"
    >
        <v-btn v-for="(item, index) in navigationItems" :key="index"
               v-show="item.visible"
               :value="item.to.name"
               @click="menuClick(item.to.name)"
               >
            <span>{{ item.title }}</span>
            <v-icon>{{ item.icon }}</v-icon>
        </v-btn>

    </v-bottom-navigation>
</template>

<script>
export default {
    name: "app-footer",

    data() {
        return {
            value: this.$route.name ,
            navigationItems:
                [
                    {
                        title: this.$t('menu_orders'),
                        icon: 'mdi-order-bool-descending-variant',
                        to: {name: "orders"},
                        action: this.forceRerender,
                        visible: this.can(['courier', 'regional_courier']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_delivery'),
                        icon: 'mdi-truck-outline',
                        to: {name: "router"},
                        action: this.forceRerender,
                        visible: this.can(['courier', 'regional_courier']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_map'),
                        icon: 'mdi-map-marker-radius-outline',
                        to: {name: "map"},
                        action: this.forceRerender,
                        visible: this.can(['courier', 'regional_courier']),
                        exact: false
                    },

                    {
                        title: this.$t('menu_notification'),
                        icon: 'mdi-message-text-outline',
                        to: {name: "notifications"},
                        action: this.forceRerender,
                        visible: this.can(['courier1', 'regional_courier1']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_profile'),
                        icon: 'mdi-account',
                        to: {name: "profile"},
                        action: this.forceRerender,
                        visible: this.can(['courier', 'regional_courier']),
                        exact: false
                    },

                ],


        };
    },
    methods: {
        menuClick(name){
            this.$router.push({
                name: name,
            })
        }
    }
};
</script>

<style scoped>
.bottomNavigation button{
    background-color: #45649f !important;
    color: #e0e0e0 !important;
}
.bottomNavigation button.v-btn:not(.v-btn--active){

    color: #e0e0e0 !important;
}
.bottomNavigation button.v-btn--active{

    color: #ffffff !important;
}


</style>
