<template>
    <v-card color="transparent" flat outlined class="py-0 my-0 px-0 mx-0 mx-auto"  >
        <v-card-text>
            <v-menu offset-y
                    bottom
                    left
                    v-model="showMenu">
                <template v-slot:activator="{ on, attrs }">
                    <v-col v-bind="attrs" v-on="on" class="py-0 my-0 px-0 mx-0 text-uppercase bold">
                        <v-icon color="white"
                                medium
                                v-bind="attrs"
                                v-on="on">mdi-dots-vertical</v-icon>
                    </v-col>

                </template>

                        <v-list>
                            <v-list-item>
                                <v-row class="py-2">
                                    <v-col cols="12" class="text-center py-0">
                                        <v-avatar class="mt-2" color="white" @click.stop="menu = !menu" size="44">
                                            <v-img :src="userAvatar" />
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="12" class="text-center px-0 ">
                                        {{ userName }}
                                    </v-col>
                                </v-row>

                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item v-for="(item, index) in navigationItems" :key="index" v-show="item.visible">
                                <v-container class="tox-cursor-format-painter cursor-pointer"

                                             :to="item.to.name"
                                             @click="menuClick(item.to.name)"
                                >
                                    {{ item.title }}
                                </v-container>

                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item >
                                <v-container class="tox-cursor-format-painter cursor-pointer" @click="logout()">
                                    {{ $t('logout') }}
                                </v-container>
                            </v-list-item>
                        </v-list>



            </v-menu>
        </v-card-text>
    </v-card>
</template>
<script>

import {mapGetters} from 'vuex'
export default {
    name: "TopMenu",
    data() {
        return {
            showMenu: false,
            loading: false,
            navigationItems:
                [
                    {
                        title: this.$t('menu_orders'),
                        icon: 'mdi-order-bool-descending-variant',
                        to: {name: "orders"},
                        action: this.forceRerender,
                        visible: this.can(['courier', 'regional_courier']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_itinerary'),
                        icon: 'mdi-truck-outline',
                        to: {name: "router"},
                        action: this.forceRerender,
                        visible: this.can(['courier', 'regional_courier']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_notification'),
                        icon: 'mdi-message-text-outline',
                        to: {name: "notifications"},
                        action: this.forceRerender,
                        visible: this.can(['1courier1', '1regional_courier1']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_profile'),
                        icon: 'mdi-account',
                        to: {name: "profile"},
                        action: this.forceRerender,
                        visible: this.can(['courier', 'regional_courier']),
                        exact: false
                    },

                ],
        }},
    computed: {
        ...mapGetters(['lang', 'showNotifyMessages', 'userName', 'userAvatar']),
    },
    methods: {

        menuClick(name){
            this.$router.push({
                name: name,
            })
        },

        async logout() {
            await this.$auth
                .logout({
                    makeRequest: true,
                    redirect: {
                        name: "login"
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('you_have_successfully_logged_out'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('error_occurred_while_exiting'))
                })
        },
    }

}
</script>
