<template>
    <v-card color="transparent" flat outlined class="py-0 my-0 px-0 mx-0 mx-auto"  >
       <v-card-text>
            <v-menu offset-y
                bottom
                left
                v-model="showLang">
            <template v-slot:activator="{ on, attrs }">
                <v-col v-bind="attrs" v-on="on" class="py-0 my-0 px-0 mx-0 text-uppercase bold">
                    {{ locale }}
                </v-col>
            </template>
            <v-list>
                <v-list-item v-for="(lang, index) in locales" :key="index">
                    <v-container class="tox-cursor-format-painter" @click="changeLanguage(index)">
                        {{ lang.name }}
                    </v-container>
                </v-list-item>
            </v-list>
        </v-menu>
       </v-card-text>
    </v-card>
</template>
<script>

import Vue from 'vue'


import {mapGetters, mapActions} from "vuex"

export default {
    name: 'Languages',
    inject: ['forceRerender'],
    mounted() {
        this.locale = this.lang
        this.getLanguages()
    },
    data() {
        return {
            showLang: false,
            locales: [],
            locale: null,
            loading: false,
    }},
    computed: {
        ...mapGetters(['lang']),
    },
    methods: {
        ...mapActions(['setLanguage']),

        async getLanguages() {
            var _this = this
            this.progress = 0
            this.loading = true
            await this.$http
                .get("courier/language", {
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.locales =  res.body
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_languages'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async changeLanguage(lng){

            if(this.$auth && this.$auth.user() && this.$auth.user().id) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (lng) {
                    formData.append('language', lng)
                }
                // Update
                await this.$http
                    .put(`courier/language/${this.$i18n.locale }`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        // console.log(res.data);
                        //this.$toastr.success(this.$t('language_has_been_updated'));
                        this.locale = lng
                        document.documentElement.lang = lng
                        this.$vuetify.lang.current = lng
                        this.$i18n.locale = lng
                        this.setLanguage(lng)
                        this.forceRerender()
                        this.setCookie('language',lng,365)
                    })
                    .catch(err => {
                        // console.log(err.data);
                        this.$toastr.error(this.$t('language_has_not_been_updated'));
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            }
            else {
                this.locale = lng
                document.documentElement.lang = lng
                this.$vuetify.lang.current = lng
                this.$i18n.locale = lng
                this.setLanguage(lng)
                this.forceRerender()
                this.setCookie('language',lng,365)
            }



        },
        setCookie(name,value,days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        }
    }

}
</script>
