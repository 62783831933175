import Vue from "vue";
import Vuetify from "vuetify/lib";

import en from 'vuetify/src/locale/en.ts'
import ru from 'vuetify/src/locale/ru.ts'

Vue.use(Vuetify);

export default new Vuetify({
    options: {
        customProperties: true,
    },
    theme: {
        themes: {
            light: {
                primary: '#45649f',
                /*
                primary: '#1976D2',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                */
            },
            //dark: {
            //  primary: colors.blue.lighten3,
            //},
        },
    },
    lang: {
        locales: { en, ru },
        current: process.env.VUE_APP_I18N_LOCALE || "en"
    },
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4
    }
});
